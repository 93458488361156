.s-card-gallery__title {
  margin-bottom: 1.25rem;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -0.03em;
}

.s-card-gallery__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.s-card-gallery__item {
  padding: 1.5rem;
}

.s-card-gallery__item__wrapper-media {
  margin-bottom: 1.25rem;
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.s-card-gallery__item__media {
  position: relative;
  padding-top: 100%;
}

.s-card-gallery__item__media img {
  position: absolute;
  inset: 0px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.s-card-gallery__item__title {
  margin-bottom: 1.25rem;
  font-family: InterMedium;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
}

.s-card-gallery__item__content {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  opacity: 0.72;
}

@media (max-width: 79.9988em) {
  .s-card-gallery__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (max-width: 63.9988em) {
  .s-card-gallery__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 47.9988em) {
  .s-card-gallery__title {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0;
  }

  .s-card-gallery__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .s-card-gallery__item {
    padding: 0.75rem;
  }

  .s-card-gallery__item__wrapper-media {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 23.4375em) {
  .s-card-gallery__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}